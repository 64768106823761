<template>
  <div>
    <v-img
      height="400px"
      :gradient="imageGradient"
      :src="image"
    >
      <v-card-text
      class="white--text display-2 font-weight-bold d-flex align-center justify-center fill-height">
        Um novo canal de vendas para sua empresa
      </v-card-text>
    </v-img>
    <div>
      <v-container class="mkt-content-container mt-10">
        <v-row class="body-2 mb-3">
          <v-col cols="12">
            <router-link to="/home">
              Home
            </router-link>
            >
            <span>
            Para fornecedores
          </span>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12" sm="6" class="pr-12">
            <span class="display-1 text--primary--color font-weight-bold">
              Para fornecedores
            </span>
            <p class="mt-10 text-justify">
              Oportunizamos que fornecedores tenham um novo canal de vendas online, presença
              digital e exposição de marca para novos compradores. Tudo
              sem nenhum investimento inicial.
            </p>
            <p class="text-justify">
              Isso faz com que nossos parceiros consigam encontrar novos compradores, sem
              necessidade de altos investimentos em e-commerces ou sites próprios, marketing e
              campanhas de publicidade, SEO, representantes e vendedores externos e entre outros
              investimentos de infraestrutura e operacional.
            </p>
            <p class="text-justify">
              Deste modo, permitimos que o fornecedor possua preços mais competitivos para
              conquistar cada vez mais clientes.
            </p>
            <p class="text-justify">
              Nosso objetivo é estender as lojas físicas para o meio digital democraticamente,
              aumentando suas vendas e reduzindo o custo de aquisição de clientes.
            </p>
            <p class="text-justify">
              A Proxpect oferece a seus clientes fornecedores os seguintes benefícios:
            </p>
            <ul>
              <li>Nova experiência de vendas online;</li>
              <li>Tráfego de diversas empresas de forma gratuita;</li>
              <li>Redução de gastos de infraestrutura e vendas;</li>
              <li>Redução de gastos em aquisição de clientes;</li>
              <li>Extensão da sua loja física para o online sem custos ou investimento inicial;</li>
              <li>Facilidade no cadastramento de produtos e promoções;</li>
              <li>Aumento de exposição online;</li>
              <li>
                Suporte para questões operacionais de frete, pagamento e cadastro de produtos;
              </li>
            </ul>
            <p class="mt-5">
              Entre em contato através do e-mail contato@proxpect.com.br.
            </p>
          </v-col>
          <v-col cols="12" sm="6" v-if="!isMobile">
            <v-img class="rounded-card" :src="corpoImage"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import capaImage from '../../assets/banner-area-fonecedor-institucional.png';
import corpoImage from '../../assets/fornecedor.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      imageGradient: this.$pxMarketplace.DEFAULT_IMAGE_GRADIENT,
      image: capaImage,
      corpoImage,
    };
  },
};
</script>
